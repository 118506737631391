.up-sm,
.up-md,
.up-lg,
.up-xl,
.up-sm-inline,
.up-md-inline,
.up-lg-inline,
.up-xl-inline {
    display: none;
}

.nobr {
    white-space: nowrap;
}

.down-sm {
    display: block;
}

.anchor {
    position: relative;
    top: -80px;
}

@include media-breakpoint-up(sm) {

    .down-md {
        display: block;
    }

    .down-md-inline {
        display: inline;
    }

    .up-sm {
        display: block;
    }

    .up-sm-inline {
        display: inline;
    }

    .down-sm,
    .down-sm-inline {
        display: none;
    }
} 

@include media-breakpoint-up(md) {

    .down-md,
    .down-md-inline {
        display: none;
    }
    .up-md {
        display: block;
    }

    .up-md-inline {
        display: inline;
    }

    .down-lg {
        display: block;
    }

    .down-lg-inline {
        display: inline;
    }
} 

@include media-breakpoint-up(lg) {

    .down-lg,
    .down-lg-inline {
        display: none;
    }

    .up-lg {
        display: block;
    }

    .up-lg-inline {
        display: inline;
    }
} 

@include media-breakpoint-up(xl) {
    .up-xl {
        display: block;
    }

    .up-xl-inline {
        display: inline;
    }
} 

.txt-shadow {
    text-shadow: 0px 0px 1px #333333;
}

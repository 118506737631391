.paragraph-carousel {
    background-color: $brand-device-bg;
    font-family: $font-family-base;
    color: $brand-copy;

    .container {
        padding: 0px 10px 50px 10px;

        .mobile-carousel {
            margin: 45px auto 0 auto;
            max-width: 300px;

            a {
                width: 100%;
                min-height: 560px;

                img {
                    width: 100%;
                    border-radius: 0px;
                    display: block;
                    margin: auto;
                    max-width: 300px;
                }

                object {
                    pointer-events: none;
                }
            }
        }

        .paragraph-content {
            h3 {
                font-size: 20px;
                font-weight: 700;
                line-height: 29px;
                margin-bottom: 30px;
                margin-top: 60px;
            }

            p {
                font-size: 17px;
                line-height: 26px;
                font-weight: 400;
                margin-bottom: 30px;
            }

            span {
                color: $brand-name;
                font-weight: 600;
            }

            .spot-heading {
                color: $spot-heading;
            }

            svg {
                width: 13px;
                height: auto;

                path {
                    stroke: $spot-heading;
                }
            }

            .row {
                margin-bottom: 25px;

                p {
                    margin-bottom: 0;
                }
            }
        }
    }
}

@media only screen and (min-width : 370px) {
    .paragraph-carousel {
        .container {
            padding: 0px 10% 70px 10%;
        }
    }
}

@include media-breakpoint-up(sm) {
    .paragraph-carousel {
        .container {
            padding: 0 0 50px 0;
        }
    }
}


@include media-breakpoint-up (md) {

    .paragraph-carousel {
        .container {
            padding-bottom: 80px;
            padding-top: 60px;

            .mobile-carousel {
                margin-top: 0;
            }

            .paragraph-content {
                h3 {
                    font-size: 32px;
                    font-weight: 700;
                    line-height: 46px;
                    margin-top: 0;
                }
            }
        }
    }
}

@include media-breakpoint-up (xl) {
    .paragraph-carousel {
        .container {

            .mobile-carousel {
                a {
                    min-height: 662px;
                }
            }
        }
    }
}
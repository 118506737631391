.contains-bob-frame-content-passage {
    background-color: $brand-sec;

    .bob-close-vis {
        color: $brand-app;
        background-color: $brand-sec;

        &:hover {
            background-color: $brand-app;
            color: #fff;
        }
    }
    .bob-window {
        min-height: 100vh;
    }
}

.bob-header img {
    padding-left: 14px;
}

.bob-frame,
.bob-msg {
    .bob-close-vis {
        padding: 4px 0.9rem 8px 0.9rem;
    }
}

.contains-s1-bob-content-success {
    margin-top: 20px;

    .bob-content {
        padding: 0 15px;
    }

    .bob-window {
        width: 100%;
        margin: 0 auto;

        .content {
            h3 {
                margin-top: 15px;
                margin-bottom: 15px;
                font-size: 25px;
                color: $brand-app;
            }
        }
    }
}

.project-somnovia {
    .contains-s1-bob-content-success,
    .contains-bob-frame-content-passage {
        .bob-header img {
            width: 150px;
        }
    }
}


@include media-breakpoint-up(md) {

    .contains-s1-bob-content-success {

        .bob-window {
            max-width: 800px;
        }
    }
}


@mixin frame-margin($amount, $breakpoint) {

    @include media-breakpoint-up(#{$breakpoint}) {

        .contains-s1-bob-content-success {
            .bob-window {
                .content-header {
                    margin-bottom: $amount;
                }
            }
        }
    }
}

@include frame-margin(15px, xs);
@include frame-margin(25px, md);
@include frame-margin(30px, lg);
@include frame-margin(45px, xl);
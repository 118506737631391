// footer
// ******


// .footer margin-top should be 0, because of bottomizer

.footer {
    padding-bottom: 50px;
    padding-top: 100px;
    color: #fff;
    font-size: 15px;
    background: $foorter-bg;

    span {
        color: $brand-name-footer;
        font-weight: 600;
    }

    hr {
        margin: 70px 0;
        width: 100%;
        border: 0;
        height: 0;
        border-top: 1px solid $brand-heading-dark;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }

    .footer-column {
        padding-top: 50px;
        color: #fff;

        a,
        a:hover {
            text-decoration: none;
            color: #fff;
        }

        a {
            opacity: 0.7;
        }

        a:hover,
        a.active {
            opacity: 1;
        }

        ul {
            list-style: none;
            padding-left: 0;

            li {
                position: relative;
                padding-left: 13px;
            }

            .bullet {
                display: block;
                position: absolute;
                top: 11px;
                left: 0px;
                background: #fff;
                width: 6px;
                height: 6px;
                border-radius: 3px;
                opacity: 0.5;
            }
        }
    }

    .footer-logo {
        height: 100px;

        .gaia-logo {
            margin-top: 16px;
        }
    }

    .footer-legal {
        line-height: 1.3;

        span {
            color: #fff;
            white-space: nowrap;
            font-weight: 400;
        }
    }

    .footer-formal-links {
        margin-top: 14px;
    }
}

.project-ceprica {
    .footer {
        color: $brand-copy;
        padding-top: 50px;

        h2 {
            color: $brand-heading-dark;
            font-weight: 600;
        }

        hr {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
            margin: 40px 0;
        }

        .footer-column {
            color: $brand-copy;
            padding-top: 0;
        }

        a {
            font-weight: 500;
        }
    }
}

.project-VR .footer {
    img {
        height: 50px;
    }
    .footer-column {
        padding-top: 0px;
    }
}


// show popovers (socials) if overlapping .container (desktop)
.footer .footer-links .container {
    overflow: visible;
}

.footer-redirect {
    display: none;
}


//////// footer mobile-nav

.footer {

    .footer-col-mobile {

        h3 {
            font-size: 30px;
        }

        h3,
        p {
            width: 60%;
            margin: 0 auto;
        }

        p {
            padding-top: 40px;

            &:before {
                content: "";
                margin-bottom: 50px;
                width: 100%;
                height: 1px;
                background-color: rgb(177, 177, 177);
                display: block;
            }

            a {
                opacity: 1;
            }
        }
    }
}

@media only screen and (min-width: 370px) {
    .footer .container {
        padding: 0px 10% 70px 10%;
    }

    .project-ceprica .footer .container {
        padding: 0px 5% 70px 5%;
    }
}


@include media-breakpoint-up(sm) {

    .footer {

        .container {
            padding: 0;
        }

        .footer-col-mobile {

            h3,
            p {
                width: 100%;
                margin: 0;
            }

            p {
                &:before {
                    display: none;
                }

                a {
                    opacity: 0.7;
                }
            }
        }
    }

    .project-ceprica .footer .container {
        padding: 0;
    }
}

@include media-breakpoint-up (md) {
    .footer {
        .footer-col-mobile {
            display: table;
            margin: 0 0 0 auto;
        }
    }
}

@include media-breakpoint-up (lg) {

    .footer {
        padding-bottom: 100px;
    }
}

////////

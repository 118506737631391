// passage

// anchor helper
.passage-anchor {
    position: relative;
    top: -75px;
}

.passage {
    background-color: $passage-bg;
    font-family: $font-family-base;
    color: $brand-copy;
    padding-top: 20px;
    padding-bottom: 0;
    margin: 0 auto;

    .container {
        padding: 40px 10px 60px 10px;

        .form-contact {
            padding: 5px;
        }

        h2 {
            font-size: 28px;
            font-weight: 700;
            line-height: 41px;
            margin-bottom: 20px;
        }

        p {
            font-size: 17px;
            line-height: 26px;
            font-weight: 400;
            margin-bottom: 30px;
        }

        span {
            color: $brand-name;
            font-weight: 600;
        }

        .box-form {
            margin-top: 30px;
            margin-bottom: 60px;
        }

        .content-box {
            background-color: $passage-box-bg;
            color: #fff;
            width: 100%;
            padding: 30px;
            border-radius: 5px;

            .box-heading {
                font-size: 17px;
                text-transform: uppercase;
            }

            .box-content {
                font-size: 13px;
                margin-bottom: 0;
            }
        }

        hr {
            width: 100%;
            border: 0;
            height: 0;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        }
    }
}

@media only screen and (min-width : 370px) {

    .passage {
        .container {
            padding: 40px 10% 60px 10%;
        }
    }
}

@include media-breakpoint-up(sm) {
    .passage {
        .container {
            padding: 60px 0;
        }
    }
}

@include media-breakpoint-up (md) {
    .passage {
        .container {
            padding: 60px 0 80px 0;

            p {
                font-size: 18px;
                margin-bottom: 40px;
            }

            .content-box {
                width: 90%;
                max-width: 360px;
                padding: 50px;
            }

            .box-form {
                margin-bottom: 90px;
            }
        }
    }
}

@include media-breakpoint-up (lg) {
    .passage {
        .container {
            h2 {
                font-size: 45px;
                line-height: 54px;
                margin-bottom: 30px;
            }
        }
    }
}
body {
    font-feature-settings: "lnum"1;
}

@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/Jost/woff2/jost-v6-latin-300.woff2') format('woff2') url('/fonts/Jost/woff/jost-v6-latin-300.woff') format('woff'),
}

@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/Jost/woff2/jost-v6-latin-regular.woff2') format('woff2'),
        url('/fonts/Jost/woff/jost-v6-latin-regular.woff') format('woff'),
}

@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/Jost/woff2/jost-v6-latin-500.woff2') format('woff2'),
        url('/fonts/Jost/woff/jost-v6-latin-500.woff') format('woff')
}

@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/Jost/woff2/jost-v6-latin-600.woff2') format('woff2'),
        url('/fonts/Jost/woff/jost-v6-latin-600.woff') format('woff')
}

@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/Jost/woff2/jost-v6-latin-700.woff2') format('woff2'),
        url('/fonts/Jost/woff/jost-v6-latin-700.woff') format('woff')
}

@font-face {
    font-family: 'Jost';
    font-style: italic;
    font-weight: 400;
    src: url('/fonts/Jost/woff2/jost-v6-latin-italic.woff2') format('woff2'),
        url('/fonts/Jost/woff/jost-v6-latin-italic.woff') format('woff'),
}
.passage.passage-infobox {
    margin-bottom: -100px;

    .container {

        .passage-infobox-text {
            background-color: $brand-sec; 
            padding: 35px 35px 0 35px; 
            margin: 0 15px 0 15px; 
            border: solid thin $brand-app; 
            border-radius: 10px
        }
    }
}
.container {
    .spacer-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        margin-top: 50px;

        .spacer-line {
            width: 100%;
            height: 1px;
            background-color: #cbcbcb;
        }

        .spacer-img {
            width: 45px;
            height: 45px;
        }
    }
}

.passage-devices {
    background-color: $brand-device-bg;
    font-family: $font-family-base;
    color: $brand-copy;

    .container {
        padding: 0 10px;

        .arrow {
            margin: 0 auto 20px auto;
            border-left: 70px solid transparent;
            border-right: 70px solid transparent;
            border-top: 60px solid white;
            border-bottom: 0 none;
            width: 0;
            height: 0;

            img {
                display: block;
                margin: auto;
                width: 137px;
                padding-bottom: 20px;
                margin-top: -2px;
            }
        }

        .passage-heading {
            padding-top: 45px;

            h2 {
                font-size: 28px;
                font-weight: 700;
                line-height: 41px;

                span {
                    color: $brand-name;
                }
            }
        }

        .passage-image img {
            margin: auto;
            display: block;
            width: 100%;
            max-width: 430px;
            padding-top: 20px;
        }

        .passage-image object {
            margin: auto;
            display: block;
            width: 100%;
            max-width: 430px;
            padding-top: 20px;
        }

        .passage-content {
            padding-top: 60px;
            margin-bottom: 30px;

            p {
                font-size: 17px;
                line-height: 26px;
                font-weight: 400;
            }

            span {
                color: $brand-name;
                font-weight: 600;
            }
        }

        hr {
            width: 100%;
            border: 0;
            height: 0;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        }
    }
}

.device-button {
    display: flex;
    justify-content: flex-end;
    padding-top: 50px;

    .btn {
        max-width: 288px;
        border: solid 2px #333;
    }
}

@media only screen and (min-width : 370px) {

    .passage-devices {
        .container {
            padding: 0px 10%;
        }
    }
}

@include media-breakpoint-up(sm) {
    .passage-devices {
        .container {
            padding: 0;
        }
    }
}

@include media-breakpoint-up(md) {
    .passage-devices {
        .container {

            .passage-heading {
                padding-top: 60px;
            }

            .passage-content {
                padding-top: 45px;
                margin-bottom: 45px;

                p {
                    font-size: 18px;
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .passage-devices {
        .container {

            .passage-heading {

                h2 {
                    font-size: 55px;
                    line-height: 60px;
                }
            }
        }
    }
}
.stripe-card {
    background-color: $logo-card-bg;
    padding: 80px 10px;

    .container {
        .card-logo {
            img {
                display: block;
                margin: auto;
                max-width: 100%;
                padding-bottom: 30px;
            }
        }

        .card-line {
            margin: auto;
            display: inline-block;
            border-top: 2px solid $brand-app;
            width: 20%;
            padding-bottom: 40px;
        }

        .card-content p {
            margin: auto;
            font-family: $font-family-base;
            font-size: 17px;
            margin-bottom: 0px;
            text-align: center;
            color: $brand-copy;

            span {
                color: $brand-name;
                font-weight: 600;
            }
        }
    }
}

// two-logos
.stripe-card.two-logos {
    .container {
        .card-logo {
            text-align: center;

            img {
                display: inline-block;
            }

            img:nth-of-type(1) {
                padding-right: 20px;
            }
        }
    }
}

// zero-logos
.stripe-card.zero-logos {
    .container {
        .card-content p {
            font-size: 17px;
            margin: auto auto 0;
            text-align: center;
        }
    }
}

@media only screen and (min-width : 370px) {

    .stripe-card {
        padding: 80px 10%;

        .container {
            .card-logo img {
                max-width: 290px;
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .stripe-card {
        padding: 80px 0;
    }

    .stripe-card.two-logos {
        .container {
            .card-logo img {
                width: 40%;
            }
        }
    }

    .stripe-card.zero-logos {
        .container {
            .card-content p {
                font-size: 24px;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .stripe-card {
        padding: 80px 40px;

        .container {

            .card-logo img {
                max-width: 100%;
                margin-right: 0;
                margin-left: auto;
                padding-bottom: 0px;
            }

            .card-line {
                transform: rotate(90deg);
                padding-bottom: 0px;
                width: 10%;
            }

            .card-content {

                p {
                    font-size: 19px;
                    text-align: left;
                }
            }
        }
    }

    .stripe-card.two-logos {
        .container {
            .card-logo img {
                width: 47%;
            }

            .card-content {
                p {
                    font-size: 18px;
                }
            }
        }
    }
}

// this is added for the university luebeck logo
// should be removed for other projects
@include media-breakpoint-up(lg) {
    .stripe-card {
        .container {
            .card-logo img {
                max-width: 370px;
            }
        }
    }
}
.containing-form {
    padding-top: 60px;
    width: 100%;

    select {
        width: 200px;
    }

    .form-control {
        border-radius: 0px;
        padding: 20px;
    }

    .form-group {
        margin-bottom: 24px;
    }
}
.section-contact {
    background-color: $contact-bg;
    font-family: $font-family-base;
    color: $brand-heading-dark;

  .container {
      padding: 0 10px 0px 10px;

      h3 {
          font-size: 20px;
          font-weight: 700;
          line-height: 29px;
          margin-bottom: 40px;
      }

      .team-container {
          flex-direction: column;
          align-items: center;
      }

      .team {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;

          img {
              width: 160px;
          }

          p {
              color: $brand-copy;
              font-size: 16px;
              line-height: 1.1;
          }
      }

      hr {
          width: 100%;
          border: 0;
          height: 0;
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          border-bottom: 1px solid rgba(255, 255, 255, 0.3);
          margin: 50px 0;
      }

      span {
          color: $brand-app;
          font-weight: 600;
          font-size: 18px;
          width: 100%;
      }

      .link-tel {
          font-weight: 600;
          text-decoration: none;
      }

      .tiny-text {
          font-size: 14px;
          line-height: 1;
      }

      a {
          text-decoration: underline;
          color: $brand-heading-dark;
      }

      a.clipboard {
          position: relative;
          cursor: pointer;
          color: #fff;
          .copy-clipboard-dialog {
              position: absolute;
              margin: 0;
              padding: 2px 4px;
              width: 105px;
              border: 1px solid gray;
              border-radius: 12px;
              top: -40px;
              left: -30px;
          }
      }

      a.clipboard:hover {
          color: rgba(255, 255, 255, 0.3);
      }

      img {
          border-radius: 50%;
          max-width: 100%;
          width: 120px;
          display: block;
      }

      .icon {
          border-radius: 0;
          width: 40px;
          height: auto;
      }

      .contact-info {
          display: none;
      }

      .box {
          background-color: $box-bg ;
          width: 100%;
          padding: 10px;
          display: flex;
          align-items: center;

          img {
              width: 40px;
              height: auto;
              margin: 10px;
              border-radius: 0;
          }

          p {
              color: $box-font;
              margin-top: 10px;
              margin-left: 10px;
          }
      }
  }
}

@media only screen and (min-width : 370px) {
    .section-contact {
        .container {
            padding: 0px 10% 0px 10%;
        }
    }
    .project-ceprica .section-contact {
        .container {
            padding: 0px 5% 70px 5%;
        }
    }
}

@include media-breakpoint-up(sm) {
    .section-contact {
        .container {
            padding: 0;

            h3 {
                font-size: 32px;
                font-weight: 700;
                line-height: 46px;
            }

            span {
                font-size: 20px;
            }
        }
    }

    .project-ceprica .section-contact {
        .container {
            padding: 0;
        }
    }
}

@include media-breakpoint-up (md) {
    .section-contact {
        .container {
            padding: 0;

            span {
                font-size: 18px;
            }

            .size {
                font-size: 20px;
            }

            .box {
                padding: 30px;
                img {
                    width: 60px;
                }

                p {
                    margin-left: 30px;
                }
            }

            .team-container {
                flex-direction: row;
            }
        }
    }
}

@include media-breakpoint-up (lg) {
    .section-contact {
        .container {
            .contact-info {
                display: block;
            }

            .contact-info-mobile {
                display: none;
            }
        }
    }
}


// anchor helper
.table-anchor {
    position: relative;
    top: -75px;
}

.table {
    margin: 40px 0;

    .line {
        margin-top: 60px;
        width: 100%;
    }

    .table-container {
        padding: 0px 15px;
    }

    .table-text {
        width: 100%;
    }

    h2 {
        font-size: 32px;

    }

    h3 {
        font-size: 28px;
        margin-top: 30px;
    }

    h2,
    h3 {
        color: $brand-heading-dark;
        font-weight: 700;
        margin-bottom: 30px;
    }

    p {
        line-height: 1.4;
        margin-bottom: 25px;

        span {
            color: $brand-heading-dark;
            font-weight: 700;
        }
    }

    li {
        margin-bottom: 10px;
    }

    li span {
        color: $brand-heading-dark;
        font-weight: 500;
    }

    .table-text-bot {
        font-size: 15px;
    }
}

.table-bg {
    background-color: $table-bg;
}

.project-VR .table {
    margin-bottom: 0px;
    padding-bottom: 50px;
}

.project-ceprica .table {
    margin: 0;
    h2, h3 {
        line-height: 1.1;
    }
}

@media only screen and (min-width : 370px) {
    .table {
        .container {
            padding: 0px 10% 0px 10%;
        }
    }
    .project-ceprica .table .container {
        padding: 0 5%;
    }
}

@include media-breakpoint-up(sm) {
    .table {

        .container {
            padding: 0;
        }
    }

    .project-ceprica .table .container {
        padding: 0;
    }

}
@include media-breakpoint-up(md) {
    .table {
        padding: 50px 0 0 0;

        h3 {
            font-size: 30px;
        }

        .table-text {
            width: 45%;
        }
    }

    .project-ceprica .table {
        padding: 0;
    }
}


@include media-breakpoint-up(lg) {
    .table {

        h2 {
            font-size: 38px;
        }

        h3 {
            font-size: 30px;
        }
    }
}


@include media-breakpoint-up(xl) {
    .table {

        h2 {
            font-size: 42px;
        }

        h3 {
            font-size: 35px;
        }
    }
}

// svg animations

.animate-icon {
    object {
        opacity: 0;
    }
}

.animate-icon {
    object.animate-icon-show {
        opacity: 1;
    }
}
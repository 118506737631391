// white button
// default styles for our buttons
// here you can add styles that should apply to all buttons
.bob-style {
    @include button-variant($brand-app, rgb(145, 145, 145), $brand-app, #fff);
    width: 80%;
    border: none;
    margin: 3px; //this is needed tp teh background shadow does not get cut off on active
    border-radius: 40px;
    font-family: 'Jost',
        sans-serif;
    font-size: 17px;
    line-height: 1.5;
    display: block;
}

// V2
.image-text {
    .bob-style {
        @include button-variant($info-btn-bg, $info-btn-border, $info-btn-hover-bg, $info-btn-hover-border);
        margin: 60px auto 30px;
        border-radius: 0px;
        border: $info-btn-border 2px solid ;
        color: $info-btn-font;
        padding: 10px 20px;
        font-weight: 600;

        &:hover {
            color: $info-btn-hover-font;
        }
    }
}

// V2

.faq-contact {
    .form-contact {
        .bob-style {
            @include button-variant($form-btn-bg, $form-btn-border, $form-btn-hover-bg, $form-btn-hover-border);
            margin: 60px auto 30px;
            border-radius: 0px;
            border: $info-btn-border 2px solid ;
            color: $info-btn-font;
            padding: 10px 20px;
            font-weight: 600;
            max-width: 70%;

            &:hover {
                color: $form-btn-hover-font;
            }
        }
    }
}

// v2

.section-hero {
    .bob-style {
        @include button-variant($hero-btn-bg, $hero-btn-border, $hero-btn-hover-bg, $hero-btn-hover-border);
        //margin: 60px auto 30px;
        border-radius: 0px;
        border: $hero-btn-border 2px solid;
        color: $hero-btn-font;
        padding: 20px 20px;
        font-weight: 600;
        width: 100%;
        max-width: 100%;
        font-size: 15px;

        &:hover {
            color: $hero-btn-hover-font;
        }
    }
}

@include media-breakpoint-up(sm) {
    .section-hero {
        .bob-style {
            font-size: 17px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .faq-contact {
        .form-contact {
            .bob-style {
                margin: 60px 0px 30px auto;
            }
        }
    }
}

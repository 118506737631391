$modal-content-fallback-border-color: #999;

body.bob-frame-open .body-content {
  display: none;
}
.body-content {
  position: relative;
}

.bob-frame {
  display: none;
  overflow: hidden;
  position: relative;
  padding-left: calc(100vw - 100%);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100%;
  z-index: 1040;
  background: #ffffff;

  &.bob-fading-in {
    position: fixed;
    top: -1;
    left: 0;
  }

  .bob-window {
    margin: 0 auto;
    position: relative;
    top: 0;
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    border: 0 none;

    .bob-header {
      img {
        display: block;
        width: 110px;
        height: auto;
        margin: 10px 0 0 0;
      }
    }
    .bob-content {
      margin-top: 25px;
    }
  }
}

.bob-frame-container {
  padding: 0 30px;
}

@include media-breakpoint-up(sm) {
  .bob-frame {
    .bob-window {
      .bob-content {
        margin-top: 30px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .bob-frame {
    .bob-window {
      .bob-content {
        margin-top: 40px;
      }
    }
  }
}


.bob-msg {
  display: none;
  position: fixed;
  top: 13px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 400px;
  z-index: 1080;
  // Remove focus outline from opened modal
  outline: none;
  border: 1px solid $modal-content-fallback-border-color; //old browsers fallback (ie8 etc)
  border: 1px solid $modal-content-border-color;
  border-radius: $border-radius-lg;
  box-shadow: 0 3px 9px rgba(0,0,0,.5);
  background-color: #ffffff;
  cursor: pointer;

  .msg-content {
    display: none;
    border-radius: $border-radius-lg;
    padding: 30px 30px 30px 25px;
    @include clearfix();

    i,
    span.glyphicons {
      float: left;
      width: 40px;
      font-size: 24px;
    }
    span:not(.glyphicons) {
      display: block;
      margin-left: 40px;
      margin-right: 40px;
    }
  }

  .msg-success {
    i {
      color: $success; // #3c763d; // #4cae4c; // #82D182;
    }
    span.glyphicons {
      fill: $success; // #3c763d; // #4cae4c; // #82D182;
    }
  }

  .msg-error,
  .msg-error-checksum,
  .msg-error-request-declined, // use .msg-error-declined instead
  .msg-error-declined,
  .msg-error-expired,
  .msg-error-notfound,
  .msg-error-insurer {
    i {
      color: $danger; // #3c763d; // #4cae4c; // #82D182;
    }
    span.glyphicons {
      fill: $danger; // #3c763d; // #4cae4c; // #82D182;
    }
  }

  .msg-warning {
    i {
      color: $warning; // #3c763d; // #4cae4c; // #82D182;
    }
    span.glyphicons {
      fill: $warning; // #3c763d; // #4cae4c; // #82D182;
    }
  }

  .msg-warning-access {
    i,
    span.glyphicons
    {
      fill: $warning; // #ffc107;
    }
  }
}

.bob-frame,
.bob-msg {

  .bob-close-vis {
    position: absolute;
    top: 13px;
    right: 19px;
    font-size: 40px;
    padding: 4px 0.9rem 8px 0.9rem;
    line-height: 39px;
    border: none;
    border-radius: 50px;
    background-color: #fff;
  
    &:focus,
    &:active {
      outline: 0 none;
      background-color: rgb(230, 230, 230);
      border-radius: 50px;
    }
  }
  
  @supports (-moz-appearance:none) {
    .bob-close-vis {
      padding: 4px 0.9rem 7px 0.9rem;
      line-height: 42px;
    }
  }
}

  body.bob-frame-open .body-content {  // this caused problems, i dont know why this was icludet in the first place
   display: none;
  }

@include media-breakpoint-up(sm) {

  .bob-msg {
    top: 30px;
  }

}


//@media (max-width: @screen-xs-max) {  bootstrap 3
@include media-breakpoint-up(sm) {
    .container {
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }
}

@include media-breakpoint-up(md) {

    .container,
    .navbar-expand>.container {
        width: 100%;
    }
}